module.exports = {
  light: {
    primary: "#00923f",
    secondary: "#8F8A87",
    accent: "#4d4d4d",
    error: "#da251c",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107"
  },
  headerColor: "white",
  headerElevation: 0
};
