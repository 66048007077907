/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from "@/i18n";
import {
  OrgUnit,
  RegistrationFlow,
  RegistrationForm,
  RegistrationStepEnum
} from "@/types";
import { PersonExtension } from "./types";

export interface RegistrationState {
  routeNames: Array<string>;
  currentFormIndex: number;
  form: RegistrationForm;
  showPolicy: boolean;
  orgUnits: Array<OrgUnit>;
  step: RegistrationStepEnum;
  demoAccount?: string;
  /**
   * registrationFlow has to be loaded async with the function provided in
   * mixins/config.ts .. This is normally done when the Register.vue component
   * is created. @see Register.vue  for details.
   */
  registrationFlow?: RegistrationFlow;
  /**
   * extensionUpdater is a list of person extensions to add to the user on creation.
   *
   * They are expected to be defined in the file
   * src/customers/<customer>/functions/ExtensionUpdater.ts
   * which must be available for each customer, even it returns void
   *
   * extensionUpdater is @optional
   */
  extensionUpdater?: ((state?: RegistrationState) => PersonExtension[]) | null;
}

export const defaultState = (): RegistrationState => {
  return {
    routeNames: [],
    currentFormIndex: 0,
    form: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      password: "",
      employeeAssignment: null,
      yearOfBirth: null,
      gender: null,
      preferredLanguage: i18n.locale,
      newsletterConsent: null,
      orgUnit: null,
      workAtDesk: null,
      mobileNumber: ""
    },
    showPolicy: false,
    step: RegistrationStepEnum.BASIC_INFO,
    orgUnits: [],
    registrationFlow: {},
    extensionUpdater: null
  };
};
