/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";
import { LmTheme, defaultTheme } from "@/styles/theme";
import { RegistrationFlow, RegistrationStepEnum } from "@/types";

@Component
export default class Config extends Vue {
  public customer = "default";
  public title: string | TranslateResult = "";
  public theme: LmTheme = defaultTheme;

  /**
   * set the current customer to the value provided in .env file
   */
  async created(): Promise<void> {
    this.customer = Config.getCustomer();
    if (this.title && this.title !== "") {
      document.title = `${this.title} | Login-Master`;
    }
    // enforce update of favicon
    this.changeFavicon(`/public/favicon.ico`);

    // load theme (TODO: seems to be useless)
    this.theme = await this.getTheme();
  }

  public static getCustomer() {
    return process.env.VUE_APP_CUSTOMER || "default";
  }

  /**
   * enforce update of favicon by adding a timestamp to the URL passed.
   * Even if you pass the same URL multiple times, a timestamp is added
   * to enforce update
   *
   * @param {string} newIconUrl - new URL of favicon; timestamp is automatically added
   */
  private changeFavicon(newIconUrl: string) {
    const links = document.getElementsByTagName("link");
    let faviconLink = null;

    // Find the favicon <link> tag
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute("rel") === "icon") {
        faviconLink = links[i];
        break;
      }
    }
    // add timestamp to enforce update
    newIconUrl += `?ts=${Date.now()}`;

    if (faviconLink) {
      // Change the href attribute to the new icon URL#
      faviconLink.setAttribute("href", newIconUrl);
    } else {
      // Favicon <link> tag not found, create a new one
      const newFaviconLink = document.createElement("link");
      newFaviconLink.setAttribute("rel", "icon");
      newFaviconLink.setAttribute("href", newIconUrl);
      document.head.appendChild(newFaviconLink);
    }
  }

  /**
   * Load a component dynamically and return it. How to use it?
   *
   * - Add <component :is="MyComponent" /> to your vue template code.
   * - Add a MyComponent var to the data section of the parent component.
   * - use this.MyComponent = await this.getComponent("mycomponent") in async created() section
   *
   * @param {string} componentName - name of the component with the path below the components folder of a customer src
   * @returns {any} a component
   *
   */
  async getComponent(componentName: string) {
    try {
      const c = await import(
        `../customers/${this.customer}/components/${componentName}.vue`
      );
      return c.default;
    } catch (_reason) {
      // if the component is not available for a certain customer, return default component
      try {
        // implement fallback
        const c = await import(
          `../customers/default/components/${componentName}.vue`
        );
        return c.default;
      } catch (_reason: unknown) {
        //if default not available, return null
        try {
          // implement second fallback
          const c = await import(`../components/${componentName}.vue`);
          return c.default;
        } catch (_reason: unknown) {
          //if default not available, return null
        }
      }
    }
    return null;
  }

  /**
   * Load a customer specific function implementation
   * functionName is the name of the file in the "functions" folder. The
   * extension .ts us automatically added
   * @param {string} functionName - name of the function file with the path below the functions folder of a customer src
   * @returns {any} an object with the corresponding functions
   *
   */
  async getFunctions(functionName: string) {
    try {
      const c = await import(
        `../customers/${this.customer}/functions/${functionName}.ts`
      );
      return c.default;
    } catch (_reason) {
      // Do not provide any fallback to make sure that function is reviewed
      throw new Error(
        `Can't load ../customers/${this.customer}/functions/${functionName}.ts (config.ts/getRegistrationFlow())`
      );
    }
  }

  /**
   * Load the customer specific registration flow
   *
   * @returns {Promise<RegistrationFlow | null>} return flow or null if flow can't be loaded
   */
  async getRegistrationFlow(): Promise<RegistrationFlow | null> {
    return this.getConfig("registrationFlow");
  }

  /**
   * Load the customer specific configs like registration flow or
   * registration form congfiguration
   *
   * @returns {Promise<RegistrationFlow | null>} return flow or null if flow can't be loaded
   */
  async getConfig(configType: "registrationFlow") {
    try {
      const c = await import(
        `../customers/${this.customer}/configs/${configType}.ts`
      );
      return c.default as RegistrationFlow;
    } catch (_reason) {
      // Do not provide any fallback to make sure that function is reviewed
      throw new Error(
        `Can't load ../customers/${this.customer}/config/${configType}.ts (config.ts/getConfig())`
      );
    }
  }

  /**
   * Load a theme dynamically and return it
   *
   * Use cases: CSS / vuetify theme changes are too complex
   */
  async getTheme() {
    try {
      const theme = await import(
        `../customers/${this.customer}/theme/theme.ts`
      );
      return theme;
    } catch (_reason) {
      return {};
    }
  }

  /**
   * get the config of a form component
   * @param {RegistrationStepEnum} stepEnum of the component
   * @returns {any} the config of the component which is derived from ComponentConfig
   */
  getFormConfig = (stepEnum: RegistrationStepEnum | string): any | null => {
    const regFlow = this.$store.getters["registration/registrationFlow"];
    return regFlow && regFlow[stepEnum] ? regFlow[stepEnum].config : null;
  };

  /**
   * get the config of a form component
   * @param {RegistrationStepEnum} stepEnum of the component
   * @returns {any} the config of the component which is derived from ComponentConfig
   */
  getGlobalConfig = (): any | null => {
    const regFlow = this.$store.getters["registration/registrationFlow"];
    return regFlow && regFlow["globalConfig"] ? regFlow["globalConfig"] : null;
  };
}
