export const defaultTheme: LmTheme = {
  light: {
    primary: "#258BD8",
    secondary: "#FF992C",
    accent: "#4d4d4d",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107"
  },
  dense: true,
  headerColor: "primary"
};

export type LmTheme = {
  light?: object;
  dense?: boolean;
  headerColor?: string;
  headerElevation?: number;
};
